import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import React, { useEffect } from "react";
const Nosotros = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section>
        <div className="container">
          <div className="row g-4">
            <div className="col-10 text-center mx-auto position-relative">
              <h1 className="position-relative fs-2">
                Buscamos la innovación en la educación legal
              </h1>
            </div>
          </div>

          {/* Images START */}
          <div className="row g-4 mt-0 ">
            <div className="col-7 ">
              <img
                className="rounded-4 w-100"
                src="assets/images/logostemis/nosotros.png"
                alt=""
              />
            </div>
            <div className="col-md-4">
              <div className="bg-grad rounded-4 mt-5 p-5 text-start">
                <h1 className="text-white">
                  “Hacemos posible una mejor educación en el ámbito legal.”
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 pt-md-5">
        <div className="container">
          {/* Title */}
          <div className="row mb-4">
            <div className="col-lg-8">
              <h2>¿Quiénes somos?</h2>
              <p className="mb-0">
                En sTemis, somos líderes en soluciones legales digitales y
                académicas, proporcionando formación y reflexión práctica sobre
                el derecho, la excelencia y la aplicación tecnológica-legal son
                nuestra firma distintiva, redefiniendo la manera en que las
                organizaciones abordan los desafíos en un entorno jurídico en
                constante evolución.
              </p>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-5 position-relative">
              <img
                src="assets/images/logostemis/estilo-vida-fotorrealista-juez.jpg"
                className="w-75 rounded"
                alt=""
              />
            </div>

            <div className="col-lg-7 mt-4 mt-lg-0">
              {/* Title */}
              <h4 className="mb-3">
                Más de 1700 estudiantes han mejorado su educación en derecho con
                nosotros{" "}
              </h4>

              {/* Content */}
              <p>
                Ofrecemos capacitaciones, consultorías y programas educativos
                desde pregrado hasta posgrado, destacándonos por la formación
                educativa que brindamos por medio de nuestros programas
                educativos de derecho, combinado con tecnología educativa, como
                nuestro Simulador de Procesos Judiciales y nuestra plataforma de
                cursos. Nuestra misión es impulsar el crecimiento y la
                excelencia, brindando soluciones prácticas e innovadoras que
                unen conocimiento legal junto con la tecnología. Así mismo
                visionamos el uso de la tecnología en la enseñanza práctica del
                derecho brindando:
              </p>

              {/* Info */}
              <ul className="list-group list-group-borderless mt-4">
                <li className="list-group-item d-flex">
                  <i className="bi bi-patch-check-fill text-success me-2"></i>
                  Softwares especializados en derecho
                </li>
                <li className="list-group-item d-flex">
                  <i className="bi bi-patch-check-fill text-success me-2"></i>
                  Clases dinámicas y practicas disponibles las 24 horas
                </li>
                <li className="list-group-item d-flex">
                  <i className="bi bi-patch-check-fill text-success me-2"></i>
                  Personalización en los paquetes de capacitaciones
                  empresariales
                </li>
                <li className="list-group-item d-flex">
                  <i className="bi bi-patch-check-fill text-success me-2"></i>
                  Contenido de valor gratuito de diversos temas jurídicos
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <h2>
              {" "}
              <span style={{ color: "#009688" }}>Aliados</span>{" "}
              <span style={{ color: "#454C5B" }}> Estrategicos</span>{" "}
            </h2>
            <div className="col-6 col-md-4 col-lg-3 mt-5">
              <div className="p-4  text-center">
                <a href="https://www.linkedin.com/company/cusirramos-cusirramos-despacho-jur%C3%ADdico">
                  <img
                    src="/assets/images/logostemis/logo_estudio_cusirramos.png"
                    alt="Estudio cucirramos"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="p-4  text-center">
                <a href="#">
                  <img
                    src="/assets/images/logostemis/Logo ICAC.svg"
                    alt="pj"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            {/* Logo item */}
            <div className="col-6 col-md-4 col-lg-3 mt-5">
              <div className="p-4  text-center">
                <a href="#">
                  <img
                    src="/assets/images/logostemis/Logo PJ.svg"
                    alt="pj"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div className="col-6 col-md-4 col-lg-3">
              <div className="p-4  text-center">
                <a href="#">
                  <img
                    src="/assets/images/logostemis/Logo ICAP.svg"
                    alt="pj"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="p-4 w-75 text-center">
                <a href="#">
                  <img
                    src="/assets/images/logostemis/Logo AEEPD.svg"
                    alt="pj"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Nosotros;
